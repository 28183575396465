// 网站归类
import React from 'react'
import Breadcrumb from '@/components/breadcrumb'
import { Outlet } from 'react-router-dom'
import { pathNameMap } from '@/configs/router.config/common.config'
import ModuleList from '@/components/module-list'
import {
  aiCommonListConfigs,
  aiOthersListConfigs,
  aiRelatedListConfigs,
} from '@/configs/router.config/out-site/ai.config'

// 模块整体架构
const OutSite: React.FC = () => {
  return (
    <div>
      <Breadcrumb breadcrumbNameMap={pathNameMap} />
      <Outlet />
    </div>
  )
}

// 模块主页
const OutSiteHomePage = () => {
  return (
    <div>
      <ModuleList title={'AI-常用'} toolListConfigs={aiCommonListConfigs} />
      <ModuleList title={'AI-相关'} toolListConfigs={aiRelatedListConfigs} />
      <ModuleList title={'AI-其它'} toolListConfigs={aiOthersListConfigs} />
      {/* <ModuleList title={'前端相关'} toolListConfigs={[]} />
      <ModuleList title={'书籍相关'} toolListConfigs={[]} /> */}
    </div>
  )
}

export { OutSiteHomePage }
export default OutSite
