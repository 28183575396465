import React, { ReactElement } from 'react'
import ToolList from '@/components/tool-list'
import { ModuleListProps } from '@/@types/components/module-list'
import './style.css'

const ModuleList = (props: ModuleListProps): ReactElement<any, any> => {
  const { title, toolListConfigs } = props
  return (
    <>
      <div className="meta-homecard-tilte">{title}</div>
      <ToolList toolListConfigs={toolListConfigs} />
    </>
  )
}

export default ModuleList
