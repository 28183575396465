// 其他归类
import React from 'react'
import Breadcrumb from '@/components/breadcrumb'
import { Outlet } from 'react-router-dom'
import { pathNameMap } from '@/configs/router.config/common.config'
import ModuleList from '@/components/module-list'
import './style.css'

// 模块整体架构
const OthersArchive: React.FC = () => {
  return (
    <div>
      <Breadcrumb breadcrumbNameMap={pathNameMap} />
      <Outlet />
    </div>
  )
}

// 模块主页
const OthersArchiveHomePage = () => {
  return (
    <div>
      <ModuleList title={'我的书架'} toolListConfigs={[]} />
    </div>
  )
}

export { OthersArchiveHomePage }
export default OthersArchive
