// 这里作为引入临时测试函数文件的地方
import { convertToNumber } from './utils/common/big-number'
import { getPV, getPVDetailInfo } from './utils/finance/basic/present-value'
import { getFVDetailInfo } from './utils/finance/basic/future-value'
import { getDiscountRateDetailInfo } from './utils/finance/basic/discount-rate'
import { getDiscountTermDetailInfo } from './utils/finance/basic/time-count'

const testFunc = () => {
  // 计算终值
  // const testResult = getFVDetailInfo({
  //   PV: 20000,
  //   rate: 0.68,
  //   timeCount: 6,
  // })

  // 计算现值
  // const testResult = getPVDetailInfo({
  //   FV: 245000,
  //   rate: 0.112,
  //   timeCount: 9,
  // })

  // 计算回报率
  // const testResult = getDiscountRateDetailInfo({
  //   PV: 50,
  //   FV: 100,
  //   timeCount: 10,
  // })

  // 计算投资期
  const testResult = getDiscountTermDetailInfo({
    PV: 10000,
    FV: 60000,
    rate: 0.09,
  })

  console.log(
    '%c Line:5 🌽 testResult',
    'font-size:18px;color:#3f7cff;background:#ea7e5c',
    testResult
  )
}

testFunc()
