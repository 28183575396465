import { message } from 'antd'
import { goto, useLocation, useNavigate } from '@/utils/hooks/router'
import { messageStyle } from './style'
import { ToolCardConfig } from '@/@types/pages/webtools'

const useAction = (props: ToolCardConfig) => {
  const { name, description, iconSrc, path, enable, isOutPath } = props
  const navigate = useNavigate()

  const { origin } = useLocation()

  const onClick = () => {
    if (enable) {
      isOutPath ? goto(path) : navigate(path)
    } else {
      message.warning({
        content: '该功能暂时不可用, 请试试其它功能吧!',
        duration: 2,
        style: messageStyle,
      })
    }
  }

  return {
    name,
    description,
    iconSrc,
    path,
    enable,
    href: origin + path,
    onClick,
  }
}

export default useAction
