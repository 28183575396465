// 使用第三方库 'lunar-typescript' 处理阳历相关计算， 农历分阴阳历
import { Solar, SolarWeek, SolarUtil, SolarSeason } from 'lunar-typescript'
import { getDateNumber } from '../date'

// 导出函数， 综合处理阳历相关信息，进行输出
const getSolarDateInfo = (timestamp: number = Date.now()) => {
  // 天次
  const { daysIndexStr } = getDaysOfYear(timestamp)
  // 周次
  const weekIndexInYear = getIndexInYear(timestamp)
  // 季度
  const { seasonIndexStr } = getSeasonOfYear(timestamp)
  // 星座
  const xingZuo = getXingZuo(timestamp)

  return { daysIndexStr, weekIndexInYear, seasonIndexStr, xingZuo }
}




// 天次
const getDaysOfYear = (timestamp: number) => {
  const [y, m, d] = getDateNumber(timestamp)
  const daysIndex = SolarUtil.getDaysInYear(y, m, d)
  const isLeapYear = SolarUtil.isLeapYear(y)
  const countDays = isLeapYear ? 366 : 365

  return {
    isLeapYear,
    daysIndexStr: `今年的第${daysIndex}天 剩余${countDays - daysIndex}天 ${
      isLeapYear ? '闰年(366天)' : '平年(365天)'
    }`,
  }
}


// 获取周次，以周日作为一周的起点，并且第一周为包含本年的第一日算起
const getIndexInYear = (timestamp: number): string => {
  const stdDate = new Date(timestamp)
  const [y, m] = getDateNumber(timestamp)
  // 以星期一作为一周的起点
  const weekDate = SolarWeek.fromDate(stdDate, 1)
  const weekIndexInYear = weekDate.getIndexInYear()
  const weekIndexInMonth = weekDate.getIndex()
  const weeksCountInMonth = SolarUtil.getWeeksOfMonth(y, m, 1)
  return `本月的第${weekIndexInMonth}周(${weeksCountInMonth}) 今年的第${weekIndexInYear}周(52) `
}

// 季度
const getSeasonOfYear = (timestamp: number) => {
  const stdDate = new Date(timestamp)
  const seasonDate = SolarSeason.fromDate(stdDate)
  const seasonIndexOfYear = seasonDate.getIndex()
  return {
    seasonIndexStr: `今年的第${seasonIndexOfYear}季度(4)`,
  }
}

// 获取星座
const getXingZuo = (timestamp: number): string => {
  const stdDate = new Date(timestamp)
  const date = Solar.fromDate(stdDate)
  const xingZuo = date.getXingZuo()
  return `${xingZuo}座`
}
export default getSolarDateInfo
