export const descListStyle = {
  maringBottom: 10,
}

export const textStyle = {
  display: 'inline-block',
  marginBottom: 5,
  marginTop: 5,
}

export const blockquoteStyle = {
  width: '100%',
}
