import { ToolCardConfig } from '@/@types/pages/webtools'
// AI-常用
export const aiCommonListConfigs: ToolCardConfig[] = [
  {
    key: 'ChatGPT',
    name: 'ChatGPT',
    description: '外网: OpenAI-GPT大模型ChatGPT',
    path: 'https://chat.openai.com/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'Gemini',
    name: 'Gemini',
    description: '外网: Goolge-Gemini',
    path: 'https://gemini.google.com/app',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'Copilot',
    name: 'Copilot',
    description: '外网: Microsoft-Copilot',
    path: 'https://copilot.microsoft.com/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'yiyan',
    name: '文心一言',
    description: '百度-文心一言',
    path: 'https://yiyan.baidu.com/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
]
// AI-相关
export const aiRelatedListConfigs: ToolCardConfig[] = [
  {
    key: 'Sora',
    name: 'Sora',
    description: `文本转视频;Creating video from text; Sora is an AI model that can create realistic and imaginative scenes from text instructions.`,
    path: 'https://openai.com/sora',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'Suno',
    name: 'Suno',
    description: 'AI创造音乐',
    path: 'https://www.suno.ai/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'Midjourney',
    name: 'Midjourney',
    description: 'AI绘画',
    path: 'https://www.midjourney.com/home',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'Cursor',
    name: 'Cursor',
    description: '外网: AI辅助编程',
    path: 'https://www.cursor.so/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
]

// AI-其它
export const aiOthersListConfigs: ToolCardConfig[] = [
  {
    key: 'Claude',
    name: 'Claude',
    description: `外网: Claude`,
    path: 'https://claude.ai/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'qianwen',
    name: '通义千问',
    description: `阿里-AI大模型`,
    path: 'https://tongyi.aliyun.com/qianwen/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
  {
    key: 'ai-maas',
    name: '紫东太初',
    description: `中科院自动化所和武汉人工智能研究院-AI大模型`,
    path: 'https://ai-maas.wair.ac.cn/',
    needShowHome: true,
    isOutPath: true,
    enable: true,
  },
]
