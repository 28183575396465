import React from 'react'
import { Space } from 'antd'
import ToolCard from './tool-card'
import { ToolListProps } from '@/@types/components/tool-list'

const ToolList: React.FC<ToolListProps> = ({ toolListConfigs }) => {
  return (
    <Space wrap>
      {toolListConfigs.map(toolCardConfig => (
        <ToolCard {...toolCardConfig} />
      ))}
    </Space>
  )
}

export default ToolList
